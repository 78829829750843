<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/stock' }">库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>出库记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <div class="btn-list">
        <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu"></i>
        </el-button>
      </div>
      <el-form
        ref="form"
        :model="orderForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="orderForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            v-model="orderForm.order_no"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="出库时间" style="margin-left: 50px">
          <el-date-picker
            v-model="orderForm.deliveryTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否售后退款" label-width="120px">
          <el-select v-model="orderForm.is_refund" placeholder="请选择">
            <el-option label="正常" value="1"></el-option>
            <el-option label="退款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="order_no" label="关联订单号" width="200px"/>
        <el-table-column prop="shop.name" label="门店名称"/>
        <el-table-column prop="good_name" label="商品名称"/>
        <el-table-column prop="good_code" label="商品条码"/>
        <el-table-column prop="good_quantity" label="商品数量"/>
        <el-table-column prop="good_price" label="商品原价"/>
        <el-table-column prop="settle_price" label="分摊金额"/>
        <el-table-column prop="is_refund" label="售后退款"/>
        <el-table-column prop="delivery_time" label="出库时间"/>
        <el-table-column prop="created_at" label="创建时间"/>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { goodRecord_api } from "@/api/shop.js"
import { httpUrl } from "@/utils/http.js"
import axios from "axios"

export default {
  data() {
    return {
      orderForm: {
        shopName: "",
        order_no:'',
        is_refund:'',
        deliveryTime: [],
      },
      currentPage: 1,
      pageSizes: [10, 20, 50],
      pageSize: 10,
      total: 0,
      tableData: [{ num: 1 }, { num: 2 }],
      num: 0,
      active: 1,
      filename: "出库记录",
    }
  },
  methods: {
    //重置
    emptySearch() {
      this.orderForm = {
        shopName: "",
        order_no:'',
        is_refund:'',
        deliveryTime: [],
      }
      this.currentPage = 1
      this.chainGoodGoodRecord()
    },
    //搜索
    search() {
      this.currentPage = 1
      this.chainGoodGoodRecord()
    },
    //改变条数
    handleSizeChange(e) {
      this.currentPage = e
      this.chainGoodGoodRecord()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.chainGoodGoodRecord()
    },
    //获取出库记录
    async chainGoodGoodRecord() {
      let res = await goodRecord_api({
        shop_name: this.orderForm.shopName,
        delivery_time_start:this.orderForm.deliveryTime[0],
        delivery_time_end:this.orderForm.deliveryTime[1],
        order_no:this.orderForm.order_no,
        is_refund:this.orderForm.is_refund,
        page: this.currentPage,
        limit: this.pageSize,
      })
      this.tableData = res.data.data.list
      this.tableData.forEach((item,index)=>{
        item.is_refund=item.is_refund==1?'正常':'退款'
      })
      this.total = res.data.data.total_count
    },
    //导出
    chainGoodExport() {
      const loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

      axios
        .get(
          `${httpUrl}api/v1/chainGood/export?shop_name=${this.orderForm.shopName}&order_no=${this.orderForm.order_no}&is_refund=${this.orderForm.is_refund}&delivery_time_start=${this.orderForm.deliveryTime[0]?this.orderForm.deliveryTime[0]:''}&delivery_time_end=${this.orderForm.deliveryTime[1]?this.orderForm.deliveryTime[1]:''}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }) // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename)
          } else {
            const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
            const a = document.createElement("a") //创建a标签
            a.style.display = "none"
            a.href = href // 指定下载链接
            a.download = this.filename //指定下载文件名
            a.click() //触发下载
            URL.revokeObjectURL(a.href) //释放URL对象
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
          loading.close()
        })
        .catch((err) => {
         this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
  },
  created() {
    this.chainGoodGoodRecord()
  },
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
  flex-wrap: wrap;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.btn-item {
  display: flex;
  align-items: center;
  i {
    font-size: 14px;
    margin-left: 3px;
  }
}
.btn-list {
  display: flex;
  align-items: center;
}
</style>